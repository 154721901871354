<template>
  <div>
    <div class="mb-4 d-flex justify-content-end">
      <button class="btn btn-primary d-flex" @click="showCompanyModal = true">
        <i class="material-icons-outlined">add</i>
        <span class="ml-2"> New Company</span>
      </button>
    </div>
    <div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <label for="">Search Company Name</label>
          <div class="form-group position-relative">
            <input
              class="form-control"
              v-model="search"
              required
              type="text"
              aria-describedby="emailHelp"
              placeholder="Enter Company Name"
            />
            <i
              v-if="search !== ''"
              @click="search = ''"
              title="Clear of items"
              class="ml-2 material-icons-outlined text-primary close-input"
              >close</i
            >
          </div>
        </div>
      </div>
      <card-table-header :headers="fields"></card-table-header>
      <cardTableBody
        :allItemsCount="companies.length"
        :items="companies"
        :page="1"
        :pages="1"
        :loading="isLoadingCompanyList"
      >
        <div
          class="
            card
            row
            p-3
            d-flex
            flex-row
            justify-content-sm-end justify-content-xl-start
          "
          v-for="(item, i) in companies"
          :key="i"
        >
          <div class="col-2 col-lg-1">{{ item.id }}</div>
          <div class="col-7 col-lg-4 text-primary">
            <router-link :to="`/companies/${item.id}`">
              <div class="mx-2">{{ item.name }}</div>
              <div class="text-dark mx-2"> Phone: {{item.phone}}</div>
            </router-link>
          </div>
          <div class="col-9 col-lg-3">
            <a :href="`mailto:${item.email}`">{{item.email}}</a>
          </div>
          <div class="col-3 col-lg-1">
            <span
              :class="`btn btn-xs btn-block btn-${
                item.isActive === 1 ? 'primary' : 'danger'
              }`"
              >{{ item.isActive === 1 ? "On" : "Off" }}</span
            >
          </div>
          <div class="col-12 col-lg-3 mt-4 mt-lg-0">
            <router-link :to="`/companies/${item.id}`"
              ><button class="btn btn-info mx-2 btn-xs">
                Reports of Company
              </button></router-link
            >
            <button
              class="btn btn-warning mx-2 btn-xs"
              @click="
                editCompany = { ...item, index: i };
                showModal = true;
              "
            >
              <i style="font-size: 14px" class="material-icons-outlined mr-2"
                >border_color</i
              >Update
            </button>
          </div>
        </div>
      </cardTableBody>
      <div class="d-flex justify-content-end align-items-center">
        <eralp-pagination
          :page="page"
          :pages="pages"
          @change="(e) => SET_PAGINATION(e, true)"
        />
      </div>
    </div>
    <modal
      okText="Save"
      title="New Company"
      v-model="showCompanyModal"
      @ok="SEND_NEW_COMPANY_TO_API"
    >
      <div
        class="alert text-center"
        v-if="alert.status"
        :class="`alert-${this.alert.variant}`"
      >
        {{ alert.message }}
      </div>
      <div class="form-group">
        <label for="name">Company Owner</label>
        <span class="ml-2 badge badge-danger">required</span>
        <input
          class="form-control"
          id="name"
          maxlength="50"
          v-model="newCompany.ownerName"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Company Owner ex: John Doe"
        />
      </div>
      <div class="form-group">
        <label for="name">Company Name</label>
        <span class="ml-2 badge badge-danger">required</span>
        <input
          class="form-control"
          id="name"
          maxlength="50"
          v-model="newCompany.name"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Company Name ex: Doe Ltd."
        />
      </div>
      <div class="form-group">
        <label for="address">Company Address</label>
        <span class="ml-2 badge badge-danger">required</span>
        <input
          class="form-control"
          id="address"
          maxlength="50"
          v-model="newCompany.address"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Address ex: Eat Street"
        />
      </div>
      <div class="form-group">
        <label for="email">Company Email</label>
        <span class="ml-2 badge badge-danger">required</span>
        <input
          class="form-control"
          id="email"
          maxlength="50"
          v-model="newCompany.email"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Email Address ex: example@example.com"
        />
      </div>
      <div class="form-group">
        <label for="email">Company Phone</label>
        <span class="ml-2 badge badge-danger">required</span>
        <input
          class="form-control"
          id="email"
          maxlength="50"
          v-model="newCompany.phone"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Phone Number ex: 123456789"
        />
      </div>
      <div class="form-group">
        <label for="email">Company Postcode</label>
        <span class="ml-2 badge badge-danger">required</span>
        <input
          class="form-control"
          id="email"
          maxlength="50"
          v-model="newCompany.postcode"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Postcode ex: 0123"
        />
      </div>
      <div class="form-group">
        <label for="email">State</label>
        <span class="ml-2 badge badge-danger">required</span>
        <v-select
          :searchable="false"
          class="style-chooser"
          :value="newCompany.state"
          @option:selected="(e) => (newCompany.state = e)"
          :clearable="false"
          placeholder="Select State of Company"
          :options="states"
        >
        </v-select>
      </div>
      <div class="form-group">
        <label for="email">Notes</label>
        <span class="ml-2 badge badge-danger">required</span>
        <textarea
          class="form-control"
          id="email"
          rows="5"
          maxlength="50"
          v-model="newCompany.note"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Note"
        />
      </div>
    </modal>
    <modal
      @ok="SEND_UPDATE_COMPANY_TO_API"
      okText="Save Changes"
      title="Update Company"
      v-model="showModal"
    >
      <div
        class="alert text-center"
        v-if="alert.status"
        :class="`alert-${this.alert.variant}`"
      >
        {{ alert.message }}
      </div>
      <div class="form-group">
        <label for="name">Company Owner</label>
        <span class="ml-2 badge badge-danger">required</span>
        <input
          class="form-control"
          id="name"
          maxlength="50"
          v-model="editCompany.ownerName"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Company Owner ex: John Doe"
        />
      </div>
      <div class="form-group">
        <label for="name">Company Name</label>
        <span class="ml-2 badge badge-danger">required</span>
        <input
          class="form-control"
          id="name"
          maxlength="50"
          v-model="editCompany.name"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Company Name ex: Doe Ltd."
        />
      </div>
      <div class="form-group">
        <label for="address">Company Address</label>
        <span class="ml-2 badge badge-danger">required</span>
        <input
          class="form-control"
          id="address"
          maxlength="50"
          v-model="editCompany.address"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Address ex: Eat Street"
        />
      </div>
      <div class="form-group">
        <label for="email">Company Email</label>
        <span class="ml-2 badge badge-danger">required</span>
        <input
          class="form-control"
          id="email"
          maxlength="50"
          v-model="editCompany.email"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Email Address ex: example@example.com"
        />
      </div>
      <div class="form-group">
        <label for="email">Company Phone</label>
        <span class="ml-2 badge badge-danger">required</span>
        <input
          class="form-control"
          id="email"
          maxlength="50"
          v-model="editCompany.phone"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Phone Number ex: 123456789"
        />
      </div>
      <div class="form-group">
        <label for="email">Company Postcode</label>
        <span class="ml-2 badge badge-danger">required</span>
        <input
          class="form-control"
          id="email"
          maxlength="50"
          v-model="editCompany.postcode"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Postcode ex: 0123"
        />
      </div>
      <div class="form-group">
        <label for="email">State</label>
        <span class="ml-2 badge badge-danger">required</span>
        <v-select
          :searchable="false"
          class="style-chooser"
          :value="editCompany.state"
          @option:selected="(e) => (editCompany.state = e)"
          :clearable="false"
          placeholder="Select State of Company"
          :options="states"
        >
        </v-select>
      </div>
      <div class="form-group">
        <label for="email">Notes</label>
        <span class="ml-2 badge badge-danger">required</span>
        <textarea
          class="form-control"
          id="email"
          rows="5"
          maxlength="50"
          v-model="editCompany.note"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Note"
        />
      </div>

      <div class="mt-4">
        <strong>Situation: </strong>
        <button
          @click="editCompany.isActive = 1"
          class="btn btn-xs mx-2"
          :class="{
            'btn-secondary': editCompany.isActive === 0,
            'btn-primary': editCompany.isActive === 1,
          }"
        >
          Active
        </button>
        <button
          @click="editCompany.isActive = 0"
          class="btn btn-xs mx-2"
          :class="{
            'btn-secondary': editCompany.isActive === 0,
            'btn-danger': editCompany.isActive === 0,
          }"
        >
          Archived
        </button>
      </div>
      <div
        class="alert text-center"
        v-if="alert.status"
        :class="`alert-${this.alert.variant}`"
      >
        {{ alert.message }}
      </div>
    </modal>
  </div>
</template>

<script>
import GetCompany from "./getCompany";
import cardTableHeader from "../ui/table/cardTableHeader.vue";
import cardTableBody from "../ui/table/cardTableBody.vue";
import Modal from "../ui/modal.vue";
export default {
  mixins: [GetCompany],
  data() {
    return {
      alert: {
        status: false,
        variant: "success",
        message: "",
      },
      newCompany: {
        name: "",
        isActive: 1,
        phone: "",
        ownerName: "",
        address: "",
        email: "",
        postcode: "",
        state: "",
        note: "",
      },
      showCompanyModal: false,
      showModal: false,
      editCompany: {},
      fields: [
        { label: "Id", sort: false, col: 12, md: 6, lg: 1, xl: 1 },
        { label: "Name", sort: false, col: 12, md: 6, lg: 4, xl: 4 },
        { label: "Email", sort: false, col: 12, md: 6, lg: 3, xl: 3 },
        { label: "Situation", sort: false, col: 12, md: 6, lg: 1, xl: 2 },
        { label: "Actions", sort: false, col: 12, md: 6, lg: 2, xl: 2 },
      ],
    };
  },
  components: {
    Modal,
    cardTableHeader,
    cardTableBody,
  },
  methods: {
    async SEND_NEW_COMPANY_TO_API(e, loading) {
      this.alert.status = false;
      if (this.newCompany.name === "") return;
      loading();
      const response = await this.$api.post("Companies", {
        name: this.newCompany.name,
        isActive: 1,
        phone: this.newCompany.phone,
        ownerName: this.newCompany.ownerName,
        address: this.newCompany.address,
        email: this.newCompany.email,
        postcode: this.newCompany.postcode,
        state: this.newCompany.state,
        note: this.newCompany.note,
      });
      if (response.result === "OK" && response.message === "OK") {
        this.alert = {
          status: true,
          variant: "success",
          message: "Company is inserted with successfully",
        };
        this.GET_INITIAL_COMPANIES_LIST();
        setTimeout(() => {
          this.showCompanyModal = false;
          this.alert.status = false;
        }, 1000);
      } else {
        this.alert = {
          status: true,
          variant: "danger",
          message: "Something went wrong. Please Try Again",
        };
      }
      loading(false);
    },
    async SEND_UPDATE_COMPANY_TO_API(e, loading) {
      this.alert.status = false;
      if (this.editCompany.name === "") return;
      loading();
      const response = await this.$api.put(`Companies/${this.editCompany.id}`, {
        name: this.editCompany.name,
        isActive: this.editCompany.isActive,
        phone: this.editCompany.phone,
        ownerName: this.editCompany.ownerName,
        address: this.editCompany.address,
        email: this.editCompany.email,
        postcode: this.editCompany.postcode,
        state: this.editCompany.state,
        note: this.editCompany.note,
      });
      if (response.result === "OK" && response.message === "OK") {
        this.alert = {
          status: true,
          variant: "success",
          message: "Company saved successfully",
        };
        this.GET_INITIAL_COMPANIES_LIST();
        setTimeout(() => {
          this.showModal = false;
          this.alert.status = false;
        }, 1000);
      } else {
        this.alert = {
          status: true,
          variant: "danger",
          message: "Something went wrong. Please Try Again",
        };
      }
      loading(false);
    },
  },
};
</script>

<style>
</style>