<template>
  <div class="d-none d-lg-flex bg-primary text-white card row p-3 flex-row">
    <div
      v-for="(header, i) in headers"
      :key="i"
      :class="`
        col-${header.col} col-md-${header.md}
        d-flex
        flex-column
        justify-content-center
        col-lg-${header.lg} col-xl-${header.xl}
      `"
    >
      <strong>{{header.label}}</strong>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      default: () => [],
    },
  },
};
</script>

<style>
</style>