<template>
  <div>
    <div v-if="loading">
      <div class="text-center">
        <span
          class="spinner-border spinner-border-sm mr-2"
          role="status"
          aria-hidden="true"
        ></span>
        Loading...
      </div>
    </div>
    <div v-else-if="items.length === 0" class="card text-center">
      <div class="card-body px-0 px-lg-4">
        <div class="my-4">
          <i
            style="font-size: 48px"
            class="text-warning material-icons-outlined"
            >warning_amber</i
          >
        </div>
        <p><i>There are no items </i></p>
      </div>
    </div>
    <div v-else>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      default: () => [],
    },
    page: {
      default: 1,
    },
    pages: {
      default: 0,
    },
    loading: {
      default: true,
    },
    allItemsCount: {
      default: 0,
    },
  },
};
</script>

<style>
</style>